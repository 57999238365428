import NanoPopup from "../components/Popups/NanoPopup";
import "../../resources/less/Troubadour/Pathway/PaidFeatureInfoPopup.less";
import "../../resources/less/components/Popups/SwitchToFreemiumPopup.less";
import "../../resources/less/components/Popups/SwitchToPremiumPopup.less";
import JQuery from "jquery";
import NanoPopupQueue from "../components/Popups/NanoPopupQueue";

export default class Paywall {
  private readonly userType: "FREEMIUM" | "PREMIUM";
  private paidFeatureInfoPopup: NanoPopup;
  private paidFeatureInfoPopupContainer: JQuery<HTMLElement>;
  private paidFeatureItem: JQuery<HTMLElement>;

  private constructor() {
    // If user is Freemium:
    // Attach click event to paid feature links and buttons that will
    // open a popup to inform the user that this is a paid feature
    this.userType = $("body").attr("data-license-type") as
      | "FREEMIUM"
      | "PREMIUM"
      | null;

    // The paywall is only relevant if the user is a teacher.
    if (this.userType === null) {
      return;
    }
    this.paidFeatureInfoPopupContainer = $("#paid-feature-info-popup");
    this.Update();
  }

  public static get instance(): Paywall {
    if (!globalThis.paywall) {
      globalThis.paywall = new Paywall();
      globalThis.paywall.initPopups();
    }
    return globalThis.paywall;
  }

  private _switchedToFreemiumPopup: NanoPopup;

  get switchedToFreemiumPopup(): NanoPopup {
    return this._switchedToFreemiumPopup;
  }

  private _switchedToPremiumPopup: NanoPopup;

  get switchedToPremiumPopup(): NanoPopup {
    return this._switchedToPremiumPopup;
  }

  private _currentlyDisplayedPopup: NanoPopup = null;

  set currentlyDisplayedPopup(value: NanoPopup) {
    this._currentlyDisplayedPopup = value;
  }

  public static isFreemium(): boolean {
    return Paywall.instance.userType === "FREEMIUM";
  }

  public Update(): void {
    this.paidFeatureItem = $("[data-paid-feature=1]");
    this.paidFeatureItem.off("click.freemium");
    if (this.userType == "FREEMIUM") {
      this.paidFeatureItem.on("click.freemium", (ev) => {
        const paidFeatureName = $(ev.currentTarget).attr("data-feature-name")
          ? $(ev.currentTarget).attr("data-feature-name")
          : "default-paid-feature";
        this.paidFeatureInfoPopupContainer.attr(
          "data-feature-name",
          paidFeatureName,
        );
        this.paidFeatureInfoPopup.Show();
      });
    }
  }

  private initPopups(): void {
    // Paid feature information popup
    this.paidFeatureInfoPopup = new NanoPopup({
      target: "#paid-feature-info-popup",
      willOpen: () => {
        if (this._currentlyDisplayedPopup) {
          this._currentlyDisplayedPopup.Hide(false, true, true);
        }
      },
      onHide: () => {
        if (this._currentlyDisplayedPopup) {
          this._currentlyDisplayedPopup.Show();
        }
      },
    });

    if ($("#switch-to-freemium-popup").length > 0) {
      this._switchedToFreemiumPopup = new NanoPopup({
        target: "#switch-to-freemium-popup",
      });
      NanoPopupQueue.instance.Add(this._switchedToFreemiumPopup, 10);
    }

    if ($("#switch-to-premium-popup").length > 0) {
      this._switchedToPremiumPopup = new NanoPopup({
        target: "#switch-to-premium-popup",
      });
      NanoPopupQueue.instance.Add(this._switchedToPremiumPopup, 10);
    }
  }
}
