import Bugsnag from "@bugsnag/js";
import { UAParser } from "ua-parser-js";

async function InitBugsnag(): Promise<void> {
  const appEnv = $("#app-env").data("appEnv");
  if (appEnv === "local") {
    return;
  }

  // This will be of limited use outside of android and chromebook (Microsoft and Apple don't include device info in the UA),
  // but is included here in case it's useful.
  const device = new UAParser().getDevice();

  new Promise(() => {
    Bugsnag.start({
      apiKey: BUGSNAG_JS_API_KEY,
      appVersion: VERSION,

      collectUserIp: false,
      releaseStage: appEnv,
      metadata: { deviceInfo: device },

      onError: function () {
        // If we're not in production mode, we don't return our errors
        return appEnv !== "local";
      },

      onBreadcrumb: function (breadcrumb) {
        if (breadcrumb.type === "request") {
          if (breadcrumb.metadata.request.length < 250) return true;

          breadcrumb.metadata.request =
            breadcrumb.metadata.request.substring(0, 247) + "...";
        }
      },
    });
  });
}

export default InitBugsnag;
